<script lang="ts" setup>
const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();
const { t } = useT();
const { insertGA } = useGA({ autoLoad: false });
const { cookiesEnabledIds } = useCookieControl();
const { checkDeviceId } = usePopupAds();

const isCookiesNoticeEnabled = computed(() => appInitData.value?.isCookiesNoticeEnabled);

let stopWatch: () => void = () => {};

const fireAnalytics = () => {
	checkDeviceId();
	insertGA();
	stopWatch();
};

stopWatch = watchEffect(() => {
	if (cookiesEnabledIds?.value?.includes("analytics") || !isGuest.value || !isCookiesNoticeEnabled.value) {
		fireAnalytics();
	}
});

if (!isGuest.value) {
	stopWatch();
}

onUnmounted(() => {
	stopWatch();
});
</script>
<template>
	<CookieControl v-if="isGuest && isCookiesNoticeEnabled" class="cookie-wrapper">
		<template #bar>
			<AText variant="tempe" class="text-coro">
				{{
					t(
						"This website uses cookies to enhance user experience and to analyze performance and traffic on our website.\n We also share information about your use of our site with our social media, advertising and analytics partners"
					)
				}}
			</AText>
			<br />
			<NuxtLink class="link" target="_blank" to="/page/cookie-notice">
				<AText :modifiers="['link', 'underline']">{{ t("More About Cookie Notice") }}</AText>
			</NuxtLink>
		</template>
	</CookieControl>
</template>

<style lang="scss">
$sidebar-width: 242px;

.cookieControl {
	.cookieControl__Bar {
		font-family: var(--font-family);
		background: var(--color-secondary-950);
		max-width: 1022px;
		width: calc(100% - $sidebar-width);
		display: flex;
		justify-self: center;
		border-radius: 12px;
		padding: 8px 16px;
		margin-left: $sidebar-width;
		margin-bottom: 25px;

		@include media-breakpoint-down(lg) {
			width: calc(100% - 32px);
			margin: 0 auto 12px;
			padding: 16px;
		}
	}

	.cookieControl__BarContainer {
		padding: 0;
		gap: 16px;
		align-items: center;

		@include media-breakpoint-down(lg) {
			gap: 0;
		}
	}

	.cookieControl__ControlButton {
		display: none;
	}

	.cookieControl__BarButtons {
		button {
			width: 120px;
			height: 40px;
			border-radius: 4px;
			padding: 0;
			text-align: center;
			font-weight: 700;
			font-size: 14px;
			line-height: 1;
			text-transform: uppercase;

			&:nth-child(1) {
				color: var(--color-gray-950);
				background: var(--color-primary-400);
				box-shadow: $button-box-shadow;

				&:hover {
					background: var(--color-primary-500);
				}
			}
			&:nth-child(2) {
				color: var(--color-primary-400);
				background: transparent;
				border: 1px solid var(--color-primary-400);

				&:hover {
					color: var(--color-primary-500);
				}
			}
			&:nth-child(3) {
				display: none;
			}

			@include media-breakpoint-down(md) {
				width: 100%;

				&:nth-child(1) {
					margin-top: 0;
				}

				&:nth-child(2) {
					margin-top: 12px;
				}
			}
		}
	}
}
</style>
